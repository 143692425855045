import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import Background from "../assets/backgroundR.jpg";

const Register = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const apiBackEnd = process.env.REACT_APP_API_BACKEND;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(`${apiBackEnd}/register`, values);

      if (data.status === "success") {
        Swal.fire({
          title: "Register Berhasil... ",
          text: "You clicked the button!",
          icon: "success",
        });
        navigate("/login");
      }
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "register Gagal",
          text:
            error.response.data.error ||
            "Email Sudah terdaftar silahkan coba email baru.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "register Gagal",
          text: error.request.data.error,
        });
      }
    }
  };
  return (
    <div>
      <section
        className="object-cover bg-repeat"
        style={{
          backgroundImage: `url(${Background})`,
        }}
      >
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className=" w-full backdrop-blur-md bg-white/30 rounded-md shadow-md md:mt-0 sm:max-w-md xl:p-0 ">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                Register Account
              </h1>
              <form
                onSubmit={handleSubmit}
                className="border bg-white/30 backdrop-blur-md shadow-lg rounded-md p-2 my-2"
              >
                <label
                  htmlFor="name"
                  className="block text-sm font-medium mb-2"
                >
                  Name:{" "}
                </label>
                <input
                  type="text"
                  name="name"
                  className="border rounded w-full p-2"
                  required
                  placeholder="name"
                  onChange={(e) =>
                    setValues({ ...values, name: e.target.value })
                  }
                />
                <label
                  htmlFor="email"
                  className="block text-sm font-medium mb-2"
                >
                  Email:{" "}
                </label>
                <input
                  required
                  type="email"
                  name="email"
                  className="border rounded w-full p-2"
                  placeholder="name@company.com"
                  onChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                />
                <label
                  htmlFor="password"
                  className="block text-sm font-medium mb-2"
                >
                  Password:{" "}
                </label>
                <input
                  required
                  type="password"
                  name="password"
                  placeholder="password"
                  className="border rounded w-full p-2"
                  onChange={(e) =>
                    setValues({ ...values, password: e.target.value })
                  }
                />
                <button
                  type="submit"
                  className="border p-2 mt-2 w-full bg-blue-500 shadow-md rounded-lg text-white"
                >
                  Submit
                </button>
              </form>
              <p className="text-sm font-light text-gray-500 ">
                Sudah Memiliki Account.{" "}
                <Link
                  to="/Login"
                  className="font-medium text-blue-600 hover:underline "
                >
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;
