import React, { useEffect } from "react";

const CartKasir = ({ title, price, image, id, setRealTime }) => {
  const handleCart = () => {
    let storeData = JSON.parse(localStorage.getItem("cart")) || [];

    // Cari apakah produk sudah ada di dalam cart berdasarkan id
    const existingProduct = storeData.find((item) => item.id === id);

    if (existingProduct) {
      // Jika produk sudah ada, tambahkan count
      existingProduct.count += 1;
    } else {
      // Jika produk belum ada, tambahkan produk baru ke cart
      storeData.push({
        id,
        title,
        price,
        image,
        count: 1, // Tambahkan count untuk produk baru
      });
    }
    setRealTime(true);
    localStorage.setItem("cart", JSON.stringify(storeData));
    console.log("Success", storeData);
  };
  return (
    <div>
      <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img
          className="rounded-t-lg  min-w-full min-h-24 max-h-24"
          src={image}
          alt="img"
        />
        <div className="p-2">
          <h5 className="mb-2 text-xs sm:text-sm md:text-md lg:text-md font-bold tracking-tight text-gray-900 dark:text-white">
            {title}
          </h5>
          <p className="mb-3 text-sm sm:text-md md:text-lg lg:text-xl font-normal text-gray-700 dark:text-gray-400">
            {price}
          </p>
          <button
            onClick={handleCart}
            className="inline-flex items-center w-full px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-blue-500 hover:bg-blue-800"
          >
            Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartKasir;
