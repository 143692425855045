import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Navigasi ke halaman utama setelah komponen dimount
    navigate("/");
  }, [navigate]);

  return <div>404 - Page Not Found</div>;
};

export default NotFound;
