import axios from "axios";
import React, { useEffect, useState } from "react";
import ButtonInvoice from "../button/buttonInvoice";

const Invoice = () => {
  const [data, setData] = useState([]);

  const apiBackEnd = process.env.REACT_APP_API_BACKEND;

  useEffect(() => {
    const getDataApi = async () => {
      try {
        const response = await axios.get(`${apiBackEnd}/invoice`);
        if (response.data.Status === "success") {
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getDataApi();
  }, []);

  return (
    <div className="">
      <div className="flex overflow-x-auto overflow-y-scroll max-h-[80vh] md:w-auto border rounded-lg">
        <table className="w-screen text-left bg-gray-50 table-auto rounded-lg">
          <thead className="border bg-gray-50 text-xs sm:text-sm md:text-md uppercase">
            <tr>
              <th
                scope="col"
                className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3 rounded-tr-lg"
              >
                No
              </th>
              <th
                colSpan={5}
                scope="col"
                className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3"
              >
                Invoice
              </th>
              <th
                scope="col"
                className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="border rounded-md">
            {data.length === 0 ? (
              <tr>
                <td colSpan={6} className="px-6 py-4 text-center">
                  Tidak Ada Data
                </td>
              </tr>
            ) : (
              data.data.map((item, index) => (
                <tr key={item.id_invoice} className="bg-white capitalize">
                  <td
                    colSpan={5}
                    className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3"
                  >
                    {index + 1}
                  </td>
                  <td className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3">
                    {item.id_invoice}
                  </td>

                  <td>
                    <div className="flex items-start justify-start">
                      <ButtonInvoice id_invoice={item.id_invoice} />
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Invoice;
