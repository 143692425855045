import React, { useEffect, useState } from "react";
import CartKasir from "./CartKasir";
import axios from "axios";
import Navbar from "./navbar";
import CartOrder from "./CartOrder";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const CartGriid = () => {
  const [category, setCategory] = useState("All");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // untuk menandakan data masih dalam proses
  const [realTime, setRealTime] = useState(true);

  const apiBackEnd = process.env.REACT_APP_API_BACKEND;

  const navigate = useNavigate();
  const getAccessToken = async () => {
    const token = Cookies.get("token");

    if (token === "") {
      navigate("/login");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    axios
      .get(`${apiBackEnd}/menu`)
      .then((res) => {
        setData(res.data.data);
        setLoading(false); // data sudah siap
      })
      .catch((err) => {
        console.log(err);
        setLoading(false); // bahkan jika terjadi error, hentikan loading
      });
    getAccessToken();
  }, [navigate]);

  const filterCategory =
    category === "All"
      ? data
      : data.filter((item) => item.category === category);

  const apiImageUrl = `${apiBackEnd}/imgmenu`;

  return (
    <>
      <Navbar />
      <div className="flex flex-col md:flex-row p-4 md:p-8">
        {/* Kategori Menu */}
        <div className="w-full md:w-2/3">
          <h1 className="text-3xl font-bold mb-4">Kasir</h1>
          <div className="flex flex-wrap space-x-2 mb-6">
            {["All", "Makanan", "Minuman"].map((filter) => (
              <button
                className={`text-white py-2 px-4 rounded mb-2 ${
                  category === filter ? "bg-blue-800" : "bg-blue-500"
                }`}
                key={filter}
                onClick={() => setCategory(filter)}
              >
                {filter}
              </button>
            ))}
          </div>

          {/* List Produk */}
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {loading
              ? // Jika masih loading, tampilkan placeholder/kartu kosong
                Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className="border rounded-lg shadow-lg p-4 xl:w-48 lg:w-42 w-42"
                    >
                      <img
                        src="https://placehold.co/600x400"
                        alt="img"
                        className="rounded-md object-cover min-w-24 min-h-28"
                      />
                      <div className="lg:text-lg md:text-md text-sm font-semibold text-left">
                        Name
                      </div>
                      <div className="lg:text-lg md:text-md text-sm text-gray-600 text-left">
                        Rp 000
                      </div>
                      <div className="flex justify-end">
                        <button className=" bg-blue-500 text-white py-1 px-4 rounded-md">
                          Cart
                        </button>
                      </div>
                    </div>
                  ))
              : // Jika data sudah ada, tampilkan produk sebenarnya
                filterCategory.map((item) => (
                  <CartKasir
                    key={item.id}
                    id={item.id}
                    title={item.name}
                    price={item.price}
                    category={item.category}
                    image={apiImageUrl + item.image}
                    setRealTime={setRealTime}
                  />
                ))}
          </div>
        </div>

        {/* Order Summary */}
        <div className="w-full md:w-1/3 lg:w-1/4 xl:w-1/3 md:ml-8 mt-8 md:mt-0">
          <CartOrder realTime={realTime} setRealTime={setRealTime} />
        </div>
      </div>
    </>
  );
};

export default CartGriid;
