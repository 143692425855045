import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

const ButtonDeleteMenu = ({ id, setRealTime }) => {
  const [showModal, setShowModal] = useState(false);

  const apiBackEnd = process.env.REACT_APP_API_BACKEND;

  const handleDelete = async () => {
    console.log(`Deleting menu with ID: ${id}`); // Tambahkan log ini
    await axios
      .delete(`${apiBackEnd}/deletemenu/${id}`)
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Berhasil Menghapus Data",
          showConfirmButton: false,
          timer: 1500,
        });
        // console.log(res.message); // Pesan sukses dari server
        setShowModal(false);
        setRealTime(true); // Memperbarui tampilan secara real-time
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  return (
    <div>
      <button
        className="p-1 sm:p-2 lg:p-3 bg-red-500 rounded-md"
        onClick={() => setShowModal(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="15"
          height="15"
          viewBox="0 0 30 30"
        >
          <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
        </svg>
      </button>

      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full sm:w-[90vw] md:w-[70vw] lg:w-[50vw] xl:w-[40vw] my-6 mx-auto">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {/* Header Modal */}
                <div className="flex items-start justify-between p-4 sm:p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold text-black">
                    Delete Menu
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-lg sm:text-xl md:text-2xl lg:text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-xl sm:text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>

                {/* Body Modal */}
                <div className="relative p-4 sm:p-6 flex-auto text-black">
                  Apakah Anda Ingin Menghapus Data id: {id}
                </div>

                {/* Footer Modal */}
                <div className="flex justify-center p-4 sm:p-5">
                  <button
                    type="submit"
                    className="text-white bg-red-500 hover:bg-red-600 font-semibold py-2 px-4 sm:py-3 sm:px-5 rounded-md transition-all"
                    onClick={handleDelete}
                  >
                    Hapus
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ButtonDeleteMenu;
