import React from "react";
import Navbar from "../components/navbar";
import Sidebar from "../components/sidebar";
import Invoices from "../components/Invoice";

const Invoice = () => {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <div className="w-screen pl-16 md:pl-24 lg:pl-36">
        <div className=" text-xs sm:text-base md:text-lg px-5 lg:px-10 mt-5">
          <Invoices />
        </div>
      </div>
    </div>
  );
};

export default Invoice;
