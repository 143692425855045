import React, { useEffect, useState } from "react";
import Logo from "../assets/Logo.png";
import axios from "axios";

const ButtonCart = ({ id_invoice, id }) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const apiBackEnd = process.env.REACT_APP_API_BACKEND;

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${apiBackEnd}/invoices/${id_invoice}`
        );
        if (response.data.status === "success") {
          setData(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const rupiah = (number) => {
    // Mengonversi ke format mata uang IDR dan menghilangkan dua angka nol di belakang koma
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0, // Menentukan minimum digit desimal menjadi 0
      maximumFractionDigits: 0, // Menentukan maksimum digit desimal menjadi 0
    }).format(number);
  };

  const subTotal = data.reduce(
    (total, item) => total + item.price.replace(".", "") * item.count,
    0
  );

  const Count = data.reduce(
    (total, item) => total + parseInt(item.count, 10), // Menjumlahkan total dengan item.count
    0 // Nilai awal untuk total
  );

  return (
    <div>
      <button
        onClick={() => setShowModal(true)}
        className="bg-green-400 disabled:bg-slate-300 border border-black hover:bg-green-600 w-full p-1 sm:p-2 lg:p-3 mt-4 rounded-md text-black"
      >
        <svg
          fill="#ffffff"
          width="15px"
          height="15px"
          viewBox="0 0 32 32"
          data-name="Layer 1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          transform="matrix(-1, 0, 0, 1, 0, 0)"
          stroke="#ffffff"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <rect
              height="1"
              transform="translate(20 51) rotate(180)"
              width="16"
              x="2"
              y="25"
            ></rect>
            <rect
              height="1"
              transform="translate(26 59) rotate(180)"
              width="16"
              x="5"
              y="29"
            ></rect>
            <rect
              height="1"
              transform="translate(39 -8) rotate(90)"
              width="19"
              x="14"
              y="15"
            ></rect>
            <rect
              height="1"
              transform="translate(21 10) rotate(90)"
              width="19"
              x="-4"
              y="15"
            ></rect>
            <path d="M24,25H23v2a2,2,0,0,1-2,2H20v1h1a3,3,0,0,0,3-3Z"></path>
            <path d="M17,26h1v1a2,2,0,0,0,2,2h1v1H20a3,3,0,0,1-3-3Z"></path>
            <path d="M2,26H3v1a2,2,0,0,0,2,2H6v1H5a3,3,0,0,1-3-3Z"></path>
            <rect height="1" width="6" x="24" y="6"></rect>
            <rect height="1" width="19" x="8" y="2"></rect>
            <path d="M5,6H6V5A2,2,0,0,1,8,3H9V2H8A3,3,0,0,0,5,5Z"></path>
            <path d="M23,6h1V5a2,2,0,0,1,2-2h1V2H26a3,3,0,0,0-3,3Z"></path>
            <path d="M30,6H29V5a2,2,0,0,0-2-2H26V2h1a3,3,0,0,1,3,3Z"></path>
          </g>
        </svg>
      </button>
      {showModal && (
        <>
          <div className="flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none mt-10 focus:outline-none">
            <div className=" w-screen sm:w-full md:w-[90vw] my-6 mx-auto">
              <div className="border-0 rounded-xl shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {/* Header Modal */}
                <div className="flex items-start justify-between bg-stone-200 text-black p-4 sm:p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <img
                    src={Logo}
                    alt="logo"
                    width={50}
                    height={50}
                    className="bg-white mr-5 rounded-md"
                  />
                  <h3 className="text-lg sm:text-md md:text-2xl lg:text-3xl font-semibold">
                    Invoice
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-lg sm:text-xl md:text-2xl lg:text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-xl sm:text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>

                {/* Body Modal */}
                <div className="relative p-4 sm:p-6 flex-auto text-black border bg-slate-100">
                  <div className="container mx-auto p-4 mt-10">
                    <div className="flex justify-between mb-4">
                      <div className="text-lg font-bold">
                        INVOICE
                        <br />
                        <span>{id_invoice}</span>
                      </div>
                      <div className="text-sm">
                        <p>Warung Ketong</p>
                        <p>Jalan Bambang Ismoyo</p>
                      </div>
                    </div>
                    <div className="overflow-x-auto overflow-y-scroll max-h-40">
                      <table className="min-w-full table-auto">
                        <thead className="bg-gray-100">
                          <tr>
                            <th className="px-2 py-1 text-left text-sm">NO</th>
                            <th className="px-2 py-1 text-left text-sm">
                              ITEM
                            </th>
                            <th className="px-2 py-1 text-left text-sm">QTY</th>
                            <th className="px-2 py-1 text-left text-sm">
                              HARGA
                            </th>
                            <th className="px-2 py-1 text-left text-sm">
                              TOTAL
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length === 0 ? (
                            <tr>
                              <td colSpan="5" className="text-center text-sm">
                                No Data
                              </td>
                            </tr>
                          ) : (
                            data.map((data, index) => (
                              <tr key={index}>
                                <td className="px-2 py-1 text-sm">
                                  {index + 1}
                                </td>
                                <td className="px-2 py-1 text-sm">
                                  {data.title}
                                </td>
                                <td className="px-2 py-1 text-sm">
                                  {data.count}
                                </td>
                                <td className="px-2 py-1 text-sm">
                                  {data.price}
                                </td>
                                <td className="px-2 py-1 text-sm">
                                  {data.total}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex justify-start mt-5">
                      <div className="text-lg font-bold mr-2">Qty: </div>
                      <div className="text-lg font-bold">{Count}</div>
                    </div>
                    <div className="flex justify-start  ">
                      <div className="text-lg font-bold mr-2">Total: </div>
                      <div className="text-lg font-bold">
                        {rupiah(subTotal)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ButtonCart;
