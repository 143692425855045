import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./page/Login";
import Register from "./page/Register";
import Menu from "./page/Menu.jsx";
import CartGrid from "./components/CartGriid.jsx";
import Invoice from "./page/Invoice.jsx";
import NotFound from "./components/404NotFound.jsx";
// import DetailInvoice from "./components/DetailInvoice.jsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CartGrid />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
