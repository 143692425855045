import React, { useState } from "react";

import Navbar from "../components/navbar";
import Sidebar from "../components/sidebar";
import TableMenu from "../components/table-menu";

const Menu = () => {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <div className="w-screes ml-24 md:ml-32 lg:ml-48">
        <div className="flex justify-end text-xs sm:text-sm md:text-md items-end px-5 lg:px-10 mt-5 "></div>
        <TableMenu />
      </div>
    </div>
  );
};

export default Menu;
