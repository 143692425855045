import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Login from "../page/Login";
import Cookies from "js-cookie";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [authToken, setAutToken] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const apiBackEnd = process.env.REACT_APP_API_BACKEND;

  useEffect(() => {
    axios.defaults.withCredentials = true;

    axios
      .get(`${apiBackEnd}/login`)
      .then((res) => {
        if (res.data.Status === "success") {
          setAutToken(true);
        } else {
          setAutToken(false);
          setMessage(res.data.error);
          navigate("/login");
        }
      })
      .catch((err) => {
        console.log(err);
        setAutToken(false);
        setMessage("Gagal Terhubung Ke Server!!!");
        navigate("/login");
      });
  }, [navigate]);

  const handleLogout = () => {
    axios
      .get(`${apiBackEnd}/logout`)
      .then(() => {
        setAutToken(false);
        navigate("/login");
      })
      .catch((err) => {
        console.log("Error Logout", err);
        setMessage("Gagal Logout. Coba Lagi Nanti", err);
      });
  };

  return (
    <nav className="bg-gradient-to-tl from-blue-500 via-blue-400 to-cyan-800 text-white shadow-lg shadow-cyan-700">
      <div className="container mx-auto flex items-center justify-between px-4 py-3">
        <div className="lg:text-xl md:text-xl text-md font-bold">
          Warunk Ketong
        </div>
        <div className="relative">
          <button
            onClick={toggleDropdown}
            className="focus:outline-none flex items-center space-x-2"
          >
            <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center">
              <svg
                className="w-3 h-3 text-gray-900"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 20h5v-1a2 2 0 00-2-2h-1a4 4 0 00-8 0H7a4 4 0 00-8 0v1h5m8 0a4 4 0 11-8 0 4 4 0 018 0zM15 7a4 4 0 110-8 4 4 0 010 8zM19 7a4 4 0 110-8 4 4 0 010 8z"
                />
              </svg>
            </div>
          </button>
          {dropdownOpen && (
            <div className="fixed right-5 mt-2 w-24 bg-gray-700 shadow-lg rounded">
              {authToken ? (
                <ul>
                  <Link to="/">
                    <div className="px-4 py-2 w-full hover:bg-gray-600 cursor-pointer">
                      Kasir
                    </div>
                  </Link>
                  <Link to="/menu">
                    <div className="px-4 py-2 w-full hover:bg-gray-600 cursor-pointer">
                      Menu
                    </div>
                  </Link>
                  <div className="px-4 py-2 w-full hover:bg-gray-600 cursor-pointer">
                    <button onClick={handleLogout}>Keluar</button>
                  </div>
                </ul>
              ) : (
                <>
                  <p>{message}</p>
                  <Login setAutToken={setAutToken} authToken={authToken} />
                  {/* <Link to="/">Logout</Link> */}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
