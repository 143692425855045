import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Logo from "../assets/Logo.png";
import Background from "../assets/Background.jpg";
import Cookies from "js-cookie";

const Login = (authToken, setAuthToken) => {
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const apiBackEnd = process.env.REACT_APP_API_BACKEND;

  const handleSubmit = async (e) => {
    e.preventDefault();

    axios.defaults.withCredentials = true;
    try {
      const { data } = await axios.post(`${apiBackEnd}/login`, login);

      if (data.status === "success") {
        Swal.fire({
          title: "Login Berhasil!",
          text: "Anda berhasil masuk ke sistem.",
          icon: "success",
        });
        navigate("/");
      } else {
        Swal.fire({
          title: "Login Gagal!",
          text: "Periksa kembali email atau password!!!.",
          icon: "error",
        });
      }
    } catch (err) {
      if (err.response) {
        // console.log(err.response.data.message);
        Swal.fire({
          icon: "error",
          title: `${err.response.data.status}`,
          text: err.response.data.message,
        });
      } else if (err.request) {
        // Request was made but no response received
        console.error("Network error:", err.request);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Tidak dapat terhubung ke server, coba lagi nanti.",
        });
      } else {
        // Something else went wrong
        console.error("Error:", err.message);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan yang tidak diketahui, coba lagi nanti.",
        });
      }
    }
  };

  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <section
        className="object-cover bg-repeat min-h-screen"
        style={{
          backgroundImage: `url(${Background})`,
        }}
      >
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full backdrop-blur-md bg-white/30 rounded-xl shadow dark:border md:mt-0 sm:max-w-md xl:p-0  ">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8  rounded-xl">
              <div className="flex justify-center items-center">
                <Link
                  to="/"
                  className="flex items-center text-2xl font-semibold text-gray-900 dark:text-white"
                >
                  <img className="w-24 h-24 mr-2" src={Logo} alt="logo" />
                  Warung Ketong
                </Link>
              </div>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required
                    onChange={(e) =>
                      setLogin({ ...login, email: e.target.value })
                    }
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                    onChange={(e) =>
                      setLogin({ ...login, password: e.target.value })
                    }
                  />
                </div>

                <button
                  type="submit"
                  className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Sign in
                </button>
                <p className="text-sm font-light text-black ">
                  Belum Memiliki Account?{" "}
                  <Link
                    to="/register"
                    className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                  >
                    Daftar
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
