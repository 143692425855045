import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";

const ButonEditMenu = ({ id, name, category, price, image, setRealTime }) => {
  console.log(id, name);

  const [data, setData] = useState({
    name: name,
    category: category,
    price: price,
    image: null, // Keep null initially if no new image is selected
  });
  const [showModal, setShowModal] = useState(false);

  const apiBackEnd = process.env.REACT_APP_API_BACKEND;

  const handleEdit = async (e) => {
    e.preventDefault();

    // Prepare form data
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("category", data.category);
    formData.append("price", data.price);
    if (data.image) {
      formData.append("image", data.image); // Only append if a new image is selected
    }

    try {
      const response = await axios.put(`${apiBackEnd}/menu/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.Status === "success") {
        Swal.fire({
          title: "Menu updated successfully!",
          icon: "success",
        });
        setShowModal(false);
        setRealTime(true); // Trigger real-time update if needed
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error updating menu!",
        icon: "error",
      });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      setData({ ...data, image: file }); // Update state with the selected image file
    }
  };

  return (
    <div>
      <button
        className="p-1 sm:p-2 lg:p-3 bg-blue-500 rounded-md"
        onClick={() => setShowModal(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="15"
          height="15"
          viewBox="0 0 50 50"
        >
          <path d="M 43.125 2 C 41.878906 2 40.636719 2.488281 39.6875 3.4375 L 38.875 4.25 L 45.75 11.125 C 45.746094 11.128906 46.5625 10.3125 46.5625 10.3125 C 48.464844 8.410156 48.460938 5.335938 46.5625 3.4375 C 45.609375 2.488281 44.371094 2 43.125 2 Z M 37.34375 6.03125 C 37.117188 6.0625 36.90625 6.175781 36.75 6.34375 L 4.3125 38.8125 C 4.183594 38.929688 4.085938 39.082031 4.03125 39.25 L 2.03125 46.75 C 1.941406 47.09375 2.042969 47.457031 2.292969 47.707031 C 2.542969 47.957031 2.90625 48.058594 3.25 47.96875 L 10.75 45.96875 C 10.917969 45.914063 11.070313 45.816406 11.1875 45.6875 L 43.65625 13.25 C 44.054688 12.863281 44.058594 12.226563 43.671875 11.828125 C 43.285156 11.429688 42.648438 11.425781 42.25 11.8125 L 9.96875 44.09375 L 5.90625 40.03125 L 38.1875 7.75 C 38.488281 7.460938 38.578125 7.011719 38.410156 6.628906 C 38.242188 6.246094 37.855469 6.007813 37.4375 6.03125 C 37.40625 6.03125 37.375 6.03125 37.34375 6.03125 Z"></path>
        </svg>
      </button>

      {showModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-full sm:w-[70vw] md:w-[50vw] lg:w-[40vw] xl:w-[30vw] my-6 mx-auto">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
              {/* Header Modal */}
              <div className="flex items-start justify-between p-4 sm:p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold text-black">
                  Edit Menu
                </h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-lg sm:text-xl md:text-2xl lg:text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="bg-transparent text-black h-6 w-6 text-xl sm:text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>

              {/* Body Modal */}
              <div className="relative p-4 sm:p-6 flex-auto text-black border">
                <form encType="multipart/form-data" onSubmit={handleEdit}>
                  <label htmlFor="name">Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={data.name}
                    id="name"
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    className="p-2 border rounded-md w-full bg-stone-50 hover:bg-slate-100"
                  />
                  <label htmlFor="category">Category:</label>
                  <select
                    name="category"
                    value={data.category}
                    required
                    className="w-full border p-2 rounded-md bg-stone-50 hover:bg-slate-100"
                    onChange={(e) =>
                      setData({ ...data, category: e.target.value })
                    }
                  >
                    <option value="Makanan">Makanan</option>
                    <option value="Minuman">Minuman</option>
                  </select>
                  <label htmlFor="price">Price</label>
                  <input
                    type="text"
                    maxLength={12}
                    value={data.price}
                    name="price"
                    id="price"
                    onChange={(e) =>
                      setData({ ...data, price: e.target.value })
                    }
                    className="p-2 border rounded-md w-full bg-stone-50 hover:bg-slate-100"
                  />
                  <label htmlFor="image">Image</label>

                  {/* Display existing image */}
                  {image && (
                    <img
                      src={image}
                      alt="foto"
                      className="rounded-full w-20 h-20"
                    />
                  )}
                  <input
                    type="file"
                    className="p-2 rounded-md bg-stone-50 hover:bg-slate-100"
                    onChange={handleImageChange}
                  />
                  <div className="flex justify-center p-4 sm:p-5">
                    <button
                      type="submit"
                      className="w-full text-white bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-4 sm:py-3 sm:px-5 rounded-md"
                    >
                      Simpan
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ButonEditMenu;
