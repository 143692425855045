import axios from "axios";
import React, { useEffect, useState } from "react";
import ButtonCart from "../button/buttonCart";
import Swal from "sweetalert2";
import GifMaintenance from "../assets/maintenance.gif";
import GifSuccess from "../assets/success.gif";

const CartOrder = ({ setRealTime, realTime }) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (realTime === true) {
      const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
      setData(cartItems);
    }
    setRealTime(false);
  }, [realTime, setRealTime]);

  //   console.log(cartItems.count);
  const minCount = (id) => {
    setData((prevItems) => {
      // Perbarui item berdasarkan id
      const updatedItems = prevItems
        .map((item) => {
          if (item.id === id) {
            // Jika count lebih dari 1, kurangi count
            if (item.count > 1) {
              return { ...item, count: item.count - 1 };
            } else {
              // Jika count menjadi 0, hapus item dari array
              return null; // Kembalikan null untuk dihapus
            }
          }
          return item; // Kembalikan item lainnya tanpa perubahan
        })
        .filter((item) => item !== null); // Hapus item yang null

      localStorage.setItem("cart", JSON.stringify(updatedItems)); // Update localStorage
      return updatedItems;
    });
  };

  const plusCount = (id) => {
    setData((prevItems) => {
      const updateData = prevItems.map((item) =>
        item.id === id ? { ...item, count: item.count + 1 } : item
      );
      localStorage.setItem("cart", JSON.stringify(updateData));
      return updateData;
    });
  };

  const rupiah = (number) => {
    // Mengonversi ke format mata uang IDR dan menghilangkan dua angka nol di belakang koma
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0, // Menentukan minimum digit desimal menjadi 0
      maximumFractionDigits: 0, // Menentukan maksimum digit desimal menjadi 0
    }).format(number);
  };

  const subTotal = data.reduce(
    (total, item) => total + item.price.replace(".", "") * item.count,
    0
  );

  const tax = subTotal * 0.03;
  const totalAll = subTotal;

  const apiBackEnd = process.env.REACT_APP_API_BACKEND;
  // .post(`${apiFrontEdn}/invoice`, { cartItems })

  const sendDataToInvoiceAPI = () => {
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    // Mengirim data ke API invoice
    axios
      .post(`${apiBackEnd}/invoice`, { cartItems })
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Payment Success",
          imageUrl: GifSuccess,
          imageWidth: 300,
          imageHeight: 200,
          imageAlt: "success",
        });
        // console.log("Data berhasil dikirim ke invoice API:", response.data);
        setShowModal(false);
        localStorage.removeItem("cart");
        setData([]);
      })
      .catch((error) => {
        console.error("Error mengirim data ke invoice API:", error);
      });
  };

  const qrisPayment = () => {
    Swal.fire({
      title: "Maintenance",
      text: "Payment Maintenance",
      imageUrl: GifMaintenance,
      imageWidth: 300,
      imageHeight: 200,
      imageAlt: "Maintenance",
    });
  };
  //   sendDataToInvoiceAPI();
  return (
    <div>
      <div className="bg-white p-4 sm:p-6 lg:p-8 shadow rounded">
        <h2 className="text-lg sm:text-xl lg:text-2xl font-bold mb-4 sm:mb-6 lg:mb-8">
          Orderan Warket
        </h2>
        <div className="space-y-2 sm:space-y-4 lg:space-y-6">
          {/* Order Item */}
          <div>
            {data.length === 0 ? (
              <div className="text-center text-gray-500 bg-slate-200 rounded-lg border py-4 w-full">
                <p>Keranjang Kosong</p>
              </div>
            ) : (
              <div className="overflow-y-auto max-h-60">
                {" "}
                {/* Membatasi tinggi maksimal */}
                {data.map((menu) => (
                  <div
                    key={menu.id}
                    className="flex justify-between items-center mb-4"
                  >
                    <div className="w-1/2">
                      <p className="text-xs sm:text-sm md:text-md lg:text-md font-semibold">
                        {menu.title}
                      </p>
                      <p className="text-gray-500 text-xs sm:text-sm md:text-md lg:text-md">
                        {rupiah(menu.price.replace(".", ""))}
                      </p>
                    </div>
                    <div className="flex items-center justify-center space-x-2 w-20 sm:w-24 lg:w-32 text-white">
                      <button
                        className="bg-red-500 p-2 rounded"
                        onClick={() => minCount(menu.id)}
                      >
                        -
                      </button>
                      <span className="text-xs sm:text-sm md:text-md lg:text-md text-black">
                        {menu.count}
                      </span>
                      <button
                        className="bg-blue-500 p-2 rounded"
                        onClick={() => plusCount(menu.id)}
                      >
                        +
                      </button>
                    </div>
                    <div className="text-sm sm:text-md lg:text-lg font-semibold w-1/4 text-right">
                      {rupiah(menu.price.replace(".", "") * menu.count)}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <hr className="my-4 sm:my-6 lg:my-8" />
        <div className="flex justify-between text-xs sm:text-sm md:text-md lg:text-md font-semibold">
          <p>Subtotal</p>
          <p>{rupiah(subTotal)}</p>
        </div>
        <div className="flex justify-between text-xs sm:text-sm md:text-md lg:text-md font-semibold">
          <p>Pajak</p>
          <p>{rupiah(tax)}</p>
        </div>
        <hr className="my-4 sm:my-6 lg:my-8" />
        <div className="flex justify-between text-xs sm:text-sm md:text-md lg:text-md font-bold">
          <p>Total</p>
          <p>{rupiah(totalAll)}</p>
        </div>
        <ButtonCart
          total={rupiah(totalAll)}
          sendDataToInvoiceAPI={sendDataToInvoiceAPI}
          showModal={showModal}
          setShowModal={setShowModal}
        />
        <button
          className="bg-orange-400 hover:bg-orange-600 border-black border w-full p-1 sm:p-2 lg:p-3 mt-4 rounded-md text-white"
          onClick={qrisPayment}
        >
          Bayar Q-RIS
        </button>
      </div>
    </div>
  );
};

export default CartOrder;
