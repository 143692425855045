import React, { useState } from "react";
import Logo from "../assets/Logo.png";

const ButtonCart = ({
  showModal,
  setShowModal,
  total,
  sendDataToInvoiceAPI,
}) => {
  //   setShowModal(false);
  const [data, setData] = useState("");
  //   const [showModal, setShowModal] = useState(false);

  const formatNumber = (value) => {
    return new Intl.NumberFormat("id-ID").format(value);
  };

  const bayaran = (e) => {
    const sData = e.target.value;
    setData(sData);
  };
  const rtotal = total.replace("Rp", "").replace(/\./g, "");

  let kembalian = data.replace(/\./g, "") - rtotal;
  if (data <= 1) {
    kembalian = -1;
  }

  return (
    <div>
      <button
        disabled={rtotal <= 0}
        onClick={() => setShowModal(true)}
        className="bg-green-400 disabled:bg-slate-300 border border-black hover:bg-green-600 w-full p-1 sm:p-2 lg:p-3 mt-4 rounded-md text-black"
      >
        Bayar Cash
      </button>
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full sm:w-[90vw] md:w-[70vw] lg:w-[50vw] xl:w-[40vw] my-6 mx-auto">
              <div className="border-0 rounded-xl shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {/* Header Modal */}
                <div className="flex items-start justify-between bg-blue-800 text-white p-4 sm:p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <img
                    src={Logo}
                    alt="logo"
                    width={50}
                    height={50}
                    className="bg-white mr-5 rounded-md"
                  />
                  <h3 className="text-lg sm:text-md md:text-2xl lg:text-3xl font-semibold">
                    Warung Ketong
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-lg sm:text-xl md:text-2xl lg:text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-xl sm:text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>

                {/* Body Modal */}
                <div className="relative p-4 sm:p-6 flex-auto text-black border bg-slate-100">
                  <p>Total</p>
                  <h1 className="font-bold text-3xl">{total}</h1>
                  <p>Bayar Sekarang</p>
                </div>
                <div className="relative p-4 ">
                  <div className="border rounded-md w-full p-2">
                    <input
                      type="text"
                      maxLength={12}
                      value={data}
                      placeholder="Masukkan Nominal Pembayaran"
                      className="w-full p-2 rounded-md"
                      onChange={bayaran}
                    />
                  </div>
                  <p>{}</p>
                </div>

                {/* Footer Modal */}
                <hr />
                <div className="px-8 my-2 flex justify-between text-xs sm:text-sm md:text-md lg:text-md font-semibold">
                  <h1 className="text-2xl">
                    Subtotal: <span className="text-xl">{total}</span>
                  </h1>
                </div>
                <div className="px-8 flex justify-between text-xs sm:text-sm md:text-md lg:text-md font-semibold">
                  <h1 className="text-xl">
                    Kembalian:{" "}
                    <span>{formatNumber(parseInt(kembalian, 10))}</span>
                  </h1>
                </div>
                <button
                  disabled={kembalian <= -1}
                  onClick={sendDataToInvoiceAPI}
                  className="w-full disabled:bg-slate-500 hover:bg-blue-800 border p-2 my-4 rounded-lg bg-blue-500 text-white"
                >
                  Bayar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ButtonCart;
