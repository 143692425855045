import axios from "axios";
import React, { useEffect, useState } from "react";
import ButtonTambahMenu from "../button/ButtonTambahMenu";
import ButtonDeleteMenu from "../button/buttonDeleteMenu";
import ButtonEditMenu from "../button/ButtonEditMenu";

const TableMenu = () => {
  const [data, setData] = useState([]);
  const [realTime, setRealTime] = useState(true);

  const apiBackEnd = process.env.REACT_APP_API_BACKEND;
  useEffect(() => {
    if (realTime === true) {
      axios
        .get(`${apiBackEnd}/menu`)
        .then((res) => {
          setData(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setRealTime(false);
    }
  }, [realTime, setRealTime]);

  const apiImage = process.env.REACT_APP_API_IMAGE;

  return (
    <div>
      <div className="flex justify-end items-end mr-10">
        <ButtonTambahMenu setRealTime={setRealTime} />
      </div>
      <div className=" sm:px-1 md:px-5 m-5 flex overflow-x-auto md:w-auto ">
        <h1>
          <table className="w-[80vw] text-left bg-gray-50 table-auto rounded-lg">
            <thead className="border bg-gray-50 text-xs sm:text-sm md:text-md uppercase">
              <tr>
                <th
                  scope="col"
                  className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3 rounded-tr-lg"
                >
                  No
                </th>
                <th
                  scope="col"
                  className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3"
                >
                  Image
                </th>
                <th
                  scope="col"
                  className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="border rounded-md">
              {data.length === 0 ? (
                <tr>
                  <td colSpan={4} className="px-6 py-4 text-center">
                    {" "}
                    Tidak Ada Data
                  </td>
                </tr>
              ) : (
                data.map((data, index) => (
                  <tr key={index} className="bg-white capitalize">
                    <td className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3">
                      {index + 1}
                    </td>
                    <td className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3">
                      {data.name}
                    </td>
                    <td className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3">
                      {data.category}
                    </td>
                    <td className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3">
                      {data.price}
                    </td>
                    <td className="px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3">
                      <img
                        src={apiImage + data.image}
                        alt="img"
                        width="50"
                        height="50"
                      />
                    </td>
                    <td>
                      <div className="text-white capitalize flex gap-2">
                        <ButtonEditMenu
                          id={data.id}
                          name={data.name}
                          category={data.category}
                          price={data.price}
                          image={apiImage + data.image}
                          setRealTime={setRealTime}
                        />
                        <ButtonDeleteMenu
                          id={data.id}
                          setRealTime={setRealTime}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {/* <tr>{total}</tr> */}
        </h1>
      </div>
    </div>
  );
};

export default TableMenu;
