import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";

const ButtonTambahMenu = ({ setRealTime }) => {
  const [showModal, setShowModal] = useState(false);
  //   console.log(realTime);
  const [input, setInput] = useState({
    name: "",
    price: "",
    category: "",
    image: null, // Jangan simpan nama file, simpan file yang diunggah
  });

  const apiBackEnd = process.env.REACT_APP_API_BACKEND;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${apiBackEnd}/menu`, input, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      //   console.log(data); // Debug respons dari server
      if (data.Status === "success") {
        Swal.fire({
          title: "Menu added successfully!",
          icon: "success",
        });
        setInput({
          name: "",
          price: "",
          category: "",
        });
        setShowModal(false);
      } else {
        Swal.fire({
          title: "Gagal Menambahkan Menu",
          icon: "error",
        });
      }
      setRealTime(true);
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while adding menu!",
      });
    }
  };

  return (
    <div>
      <button
        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm p-2 rounded "
        type="button"
        onClick={() => setShowModal(true)}
      >
        Tambah Menu
      </button>
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Tambah Menu</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <form
                    encType="multipart/form-data"
                    className="w-[50vh] capitalize"
                    onSubmit={handleSubmit}
                  >
                    <div className="mb-4">
                      <label htmlFor="name">Name:</label>
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={input.name}
                        required
                        className="w-full border p-2 rounded-md bg-stone-100 hover:bg-slate-200"
                        onChange={(e) =>
                          setInput({ ...input, name: e.target.value })
                        }
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="price">Price:</label>
                      <input
                        type="number"
                        name="price"
                        value={input.price}
                        placeholder="30.000"
                        required
                        className="w-full border p-2 rounded-md bg-stone-100 hover:bg-slate-200"
                        onChange={(e) =>
                          setInput({ ...input, price: e.target.value })
                        }
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="category">Category:</label>
                      <select
                        name="category"
                        required
                        value={input.category}
                        className="w-full border p-2 rounded-md bg-stone-50 hover:bg-slate-200"
                        onChange={(e) =>
                          setInput({ ...input, category: e.target.value })
                        }
                      >
                        <option value="">Pilih Kategori</option>
                        <option value="Makanan">Makanan</option>
                        <option value="Minuman">Minuman</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <label htmlFor="image">Image:</label>
                      <input
                        type="file"
                        accept="image/*"
                        required
                        className="w-full border p-2 rounded-md bg-slate-50 hover:bg-slate-200"
                        onChange={(e) =>
                          setInput({ ...input, image: e.target.files[0] })
                        }
                      />
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="p-2 border bg-blue-500 hover:bg-blue-800 rounded-md w-full text-white"
                      >
                        Simpan
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </div>
  );
};

export default ButtonTambahMenu;
