import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const [activeSidebar, setActiveSidebar] = useState("");

  useEffect(() => {
    // Set the active sidebar based on the current path
    if (location.pathname.includes("/menu")) {
      setActiveSidebar("Menu");
    } else if (location.pathname.includes("/invoice")) {
      setActiveSidebar("Invoice");
    }
  }, [location]);

  return (
    <div className="fixed ">
      <div className="w-20 sm:w-24 md:w-32 lg:w-48 p-3 sm:p-4 text-xs sm:text-sm font-semibold space-y-4">
        <Link
          to="/menu"
          className={`block w-full text-left p-2 rounded ${
            activeSidebar === "Menu" ? "bg-gray-300" : ""
          }`}
        >
          Menu
        </Link>
        <Link
          to="/invoice"
          className={`block w-full text-left p-2 rounded ${
            activeSidebar === "Invoice" ? "bg-gray-300" : ""
          }`}
        >
          Invoice
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
